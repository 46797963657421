.habit-card-green {
    background-color: #C1E7C5!important;
}
.habit-card-red {
    background-color: #EA9C9C !important;
}

.icon22 {
    width: 23px !important;
    height: 24px !important;
    float:right;
    padding-top: 3px;
}

#streak-image {
    width: 25px !important;
    height: 25px;
    float: left;
    padding-left: 2px;
    padding-bottom: 3px;
}

@media only screen and (max-width: 550px) {
    .phone_card {
        margin-left: 20% !important;
    }
}


@keyframes glowing2 {
  0% { box-shadow: 0px 0 40px -10px #E50000 ; }
  40% { box-shadow: 0px 0 40px 20px #E50000 ; }
  60% { box-shadow: 0px 0 40px 20px #E50000 ; }
  100% { box-shadow: 0px 0 40px -10px #E50000 ; }
}
.button-glow-red {
  animation: glowing2 3000ms infinite;
}
