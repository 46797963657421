#grid-card {
    margin: auto;
}

.scalingWidth {
    background-color:#edeff2 !important;
    width: calc(800px - 16vw);
    max-width: 100%;
    min-width: 30%;
    margin: 0 auto;
}