#upgrade-button {
    background-color: #42a829;
    color: white;
    height: 3.5vh;
    width: 95%;
    min-height: 3.5vh;
}

@keyframes glowing {
  0% { box-shadow: 0px 0 40px -10px #c4a300; }
  40% { box-shadow: 0px 0 40px 20px #c4a300; }
  60% { box-shadow: 0px 0 40px 20px #c4a300; }
  100% { box-shadow: 0px 0 40px -10px #c4a300; }
}
.button-glow {
  animation: glowing 3000ms infinite;
}
