
.main-grid {
    /* padding-top: 2vh; */
    margin-left: auto;
}

#left-navbar {
    flex-direction: column
}

.flex-col-scroll {
    flex-grow: 1;
    overflow: auto;
    width:100%;
    min-height: 100%;
    margin-bottom: 5%
}
.center-vertical {
    height: 20%;
    margin: 0;
    top:50%;
    position: absolute;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    float:left;
    padding-left: 5%;
}

.underline:hover {
    text-decoration: underline;
}
#container {
    margin-left: 5% !important;
}
.bottom-border {
    border-bottom: 1px solid #2d2e2d;
}
/* #grid-container {
    padding-right: 5%;
} */


@media only screen and (max-width: 950px) {
    .underline {
      font-size: 15px !important;
      padding-bottom: 20% !important;
    }
    .font-size {
        font-size: 20px !important;
    }
}

@media only screen and (max-width: 820px) {
    .underline {
      font-size: 11px !important;
      padding-bottom: 25% !important;
    }
    .font-size {
        font-size: 18px !important;
    }
}

@media only screen and (max-width: 550px) {
    .underline {
      font-size: 9px !important;
      padding-bottom: 30% !important;
    }
    .font-size {
        font-size: 16px !important;
    }
    .phone_card {
        padding-left: 10%;
    }
}